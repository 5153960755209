import artifactEffects from './artifactEffects'
import factionSkills from './factionSkills'
import heroClasses from './heroClasses'
import skillNames from './skillNames'
import technicalUnitAbilities from './technicalUnitAbilities'
import unitAbilities from './unitAbilities'
import unitGroupings from './unitGroupings'
import unitSpells from './unitSpells'
import artifactParts from './artifactParts'
import artifactQualities from './artifactQualities'
import artifactSlots from './artifactSlots'
import allUnits from './allUnits'
import resourceTypes from './resourceTypes'

function format (list) {
  return list.reduce((acc, curr) => {
    const key = curr.key
    acc[key] = {
      value: key,
      label: curr.label
    }
    acc[key].desc = curr.description ? curr.description : curr.label
    return acc
  }, {})
}

export default {
  artifactEffects: format(artifactEffects),
  factionSkills: format(factionSkills),
  heroClasses: format(heroClasses),
  skillNames: format(skillNames),
  technicalUnitAbilities: format(technicalUnitAbilities),
  unitAbilities: format(unitAbilities),
  unitGroupings: format(unitGroupings),
  unitSpells: format(unitSpells),
  artifactParts: format(artifactParts),
  artifactQualities: format(artifactQualities),
  artifactSlots: format(artifactSlots),
  allUnits: format(allUnits),
  resourceTypes: format(resourceTypes)
}
