<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>关于我</v-card-title>
          <v-card-text v-if="isLoading">
            <v-skeleton-loader
              type="list-item-avatar, list-item-two-line"
            ></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else-if="info">
            <div class="d-flex flex-column align-center">
              <v-avatar class="mb-2" @click="onClick">
                <img
                  :src="info.image"
                  alt="avatar"
                >
              </v-avatar>
              <div class="text-subtitle-1 mb-1">{{ info.name }}</div>
              <div class="text-caption mb-1" v-html="info.describe"></div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-btn
              @click="getInfo"
              outlined
              color="warning"
            >
              <v-icon>mdi-refresh</v-icon>
              加载失败
            </v-btn>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-sheet class="py-6">
            <v-img
              src="https://i.imgtg.com/2022/03/20/bqkij.webp"
              aspect-ratio="1"
              max-width="100"
              max-height="100"
              class="mx-auto mb-2"
            ></v-img>
            <div class="text-center text-caption grey--text text--darken-1">扫码为我发电</div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAdminInfo } from '@/apis/common'

export default {
  data () {
    return {
      info: null,
      isLoading: false
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    getInfo () {
      this.isLoading = true
      getAdminInfo().then(({ data: info }) => {
        this.info = info
      }).finally(() => {
        this.isLoading = false
      })
    },
    onClick () {
      // window.open('https://space.bilibili.com/37704405', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
