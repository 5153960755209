import store from '@/store'

function show ({ text, color = 'primary', timeout = 5000 }) {
  store.commit('app/showNotification', {
    text,
    color,
    timeout
  })
}

export default {
  install (Vue) {
    Vue.prototype.$noti = {
      show,
      info (text) {
        show({ text, color: 'primary' })
      },
      success (text) {
        show({ text, color: 'success' })
      },
      error (text) {
        show({ text, color: 'error' })
      }
    }
  }
}
