import store from '@/store'

class Auth {
  constructor (callback) {
    const isShow = store.state.auth.dialog
    if (!isShow) {
      store.commit('auth/setCallback', typeof callback === 'function' ? callback : null)
      store.commit('auth/showAuthDialog')
    } else {
      console.error('auth-dialog is opening')
    }
  }
}

export default function (options) {
  return new Auth(options)
}
