<template>
  <v-dialog :value="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>微信扫码关注『游戏鼓励Jun』</v-card-title>
      <v-card-text>
        <v-alert
          class="mb-0"
          color="#536dfe"
          outlined
          dense
        >
          进入公众号，回复『验证码』三个字获取动态码
        </v-alert>
        <div class="d-flex justify-center align-center">
          <v-responsive :aspect-ratio="1" max-width="300px">
            <img :src="require('assets/qrcode.jpg')" alt="qrcode" class="qrcode">
          </v-responsive>
          <!-- <v-img :src="require('assets/qrcode.jpg')" :aspect-ratio="1" class="qrcode elevation-0 rounded-xl">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img> -->
        </div>
        <div class="ma-auto" style="max-width: 260px">
          <v-text-field
            label="动态码"
            placeholder="请输入动态码（5位）"
            :maxlength="5"
            type="text"
            v-model="value"
            outlined
            :disabled="isLoading"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="onClose"
          :disabled="isLoading"
        >
          取消
        </v-btn>
        <v-btn
          color="success"
          text
          @click="onFinish"
          :loading="isLoading"
        >
          确认
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      value: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState('auth', ['dialog', 'callback'])
  },
  methods: {
    ...mapMutations('auth', ['reset']),
    onFinish () {
      this.verify(this.value)
    },
    checkInput (code) {
      if (!code || typeof code !== 'string') {
        this.$noti.info('请输入动态码')
        return false
      }
      if (code.length !== 5 || !/^[0-9]{5}$/m.test(code)) {
        this.$noti.info('动态码必须是 5 位数字')
        return false
      }
      return true
    },
    verify (code) {
      if (!this.checkInput(code)) {
        return
      }
      this.callback && this.callback(code, {
        setLoading: this.setLoading,
        setClose: this.onClose
      })
    },
    onClose () {
      this.value = ''
      this.reset()
    },
    setLoading (isLoading) {
      this.isLoading = isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode {
  max-width: 100%;
}
</style>
