<template>
  <div class="d-flex flex-column justify-center align-center my-6">
    <v-icon :size="48" class="mb-2" color="indigo lighten-3">{{ icon }}</v-icon>
    <div class="text-h6 grey--text">{{ tips }}</div>
  </div>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-package-variant'
    },
    tips: {
      type: String,
      default: '没有更多数据啦~'
    },
    size: {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
