import { getEditTimeFromLS, saveEditTimeToLS } from '@/utils/article'

const state = {
  mainDrawer: true,
  notification: false,
  notificationText: '',
  notificationColor: '',
  notificationTimeout: 5000,
  editTimeCollection: getEditTimeFromLS()
}
const getters = {

}

const mutations = {
  updateMainDrawer (state, flag) {
    state.mainDrawer = !!flag
  },
  toggleMainDrawer (state) {
    state.mainDrawer = !state.mainDrawer
  },
  updateNotification (state, flag) {
    state.notification = flag
  },
  showNotification (state, { text, color, timeout }) {
    state.notification = false
    state.notificationText = text
    state.notificationColor = color
    state.notificationTimeout = timeout
    state.notification = true
  },
  updateArticleEditTime (state, { id, time }) {
    state.editTimeCollection[id] = time
  }
}

const actions = {

}

export const subscription = (store, mutation) => {
  if (mutation.type === 'app/updateArticleEditTime') {
    const collection = store.state.app.editTimeCollection
    saveEditTimeToLS(collection)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
