import menuConfig from 'configs/menu'

function formatMenu (menu) {
  const topMenu = menu.topMenu
  if (typeof topMenu === 'string') {
    return {
      name: topMenu
    }
  }
  return topMenu
}

export default {
  computed: {
    currentMenu () {
      const matchedList = this.$route.matched
      const matched = matchedList.find(route => !!menuConfig[route.name])
      if (!matched) {
        return menuConfig._default
      }
      const matchedName = matched.name
      return menuConfig[matchedName] || menuConfig._default
    },
    topMenus () {
      const isMobile = this.$vuetify.breakpoint.smAndDown
      return Object.keys(menuConfig)
        .map(key => {
          return {
            ...formatMenu(menuConfig[key]),
            key
          }
        })
        .filter(menu => !!menu.name)
        .filter(menu => !(isMobile && menu.hideOnMobile))
    },
    rightDrawer () {
      const menu = this.currentMenu
      return menu.rightDrawer
    }
  }
}
