const state = {
  dialog: false,
  callback: null,
  isLoading: false
}
const getters = {

}

const mutations = {
  showAuthDialog (state) {
    state.dialog = true
  },
  hideAuthDialog (state) {
    state.dialog = false
    state.callback = null
  },
  setCallback (state, callback) {
    state.callback = callback
  },
  reset (state) {
    state.dialog = false
    state.callback = null
  },
  setLoading (state, loading) {
    state.isLoading = loading
  }
}

const actions = {

}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
