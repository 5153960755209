import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Empty from 'components/Empty.vue'
import 'assets/styles/_global.scss'
import 'assets/styles/gfm_lite.scss'
import auth from 'components/auth'
import Notification from 'utils/notification'
// import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
import 'dayjs/esm/locale/zh-cn'
import 'nprogress/nprogress.css'

Vue.config.productionTip = false
Vue.prototype.$auth = auth

Vue.component('empty', Empty)
// Vue.use(require('vue-script2'))
// Vue.use(Adsense)
Vue.use(Notification)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
