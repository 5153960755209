<template>
  <v-app-bar app clipped-left clipped-right dark color="#536dfe" elevation="0">
    <!-- <v-app-bar-nav-icon @click="toggleMainDrawer"></v-app-bar-nav-icon> -->
    <!-- <v-app-bar-nav-icon @click="back" v-if="$route.path !== '/'">
      <v-icon>mdi-arrow-left</v-icon>
    </v-app-bar-nav-icon> -->
    <v-toolbar-title @click="toMain" class="title flex-shrink-0 mr-8">鼓励 Jun 的游戏库</v-toolbar-title>
    <!-- 菜单项 -->
    <!-- 手机 -->
    <template v-if="isMobile" v-slot:extension>
      <v-tabs hide-slider>
        <v-tab v-for="(menu, i) in topMenus"
          :key="i"
          :to="{ name: menu.key }"
          link
        >{{ menu.name }}</v-tab>
      </v-tabs>
    </template>
    <!-- PC -->
    <v-tabs v-if="!isMobile" hide-slider>
      <v-tab v-for="(menu, i) in topMenus"
        :key="i"
        :to="{ name: menu.redirect ? '' : menu.key }"
        @click="handleRedirect(menu)"
        link
      >{{ menu.name }}</v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
import { mapMutations } from 'vuex'
import MixinMenu from 'mixins/MixinMenu'

export default {
  mixins: [MixinMenu],
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.xs
    }
    // slotName () {
    //   return this.isMobile ? 'extension' : 'defaualt'
    // }
  },
  methods: {
    ...mapMutations('app', ['toggleMainDrawer']),
    back () {
      this.$router.back()
    },
    toMain () {
      this.$router.push({
        name: 'Articles'
      })
    },
    handleRedirect (menu) {
      if (menu.redirect) {
        window.open(menu.redirect, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  cursor: pointer;
}
</style>
