<template>
  <v-app>
    <!-- 左边栏 -->
    <!-- <main-drawer></main-drawer> -->

    <!-- 右边栏 -->
    <secondary-drawer></secondary-drawer>

    <!-- 顶栏 -->
    <app-bar></app-bar>

    <!-- 根据应用组件来调整你的内容 -->
    <v-main class="main">
      <!-- 给应用提供合适的间距 -->
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
        <a class="text-caption mb-2 ispn" href="http://beian.miit.gov.cn/" target="_blank">
          粤ICP备17041150号
        </a>
      </v-container>
    </v-main>
    <auth-dialog></auth-dialog>
    <notification></notification>
  </v-app>
</template>

<script>
// import MainDrawer from 'components/app/MainDrawer.vue'
import SecondaryDrawer from 'components/app/SecondaryDrawer.vue'
import AppBar from 'components/app/AppBar.vue'
import Notification from 'components/app/Notification.vue'
import AuthDialog from 'components/auth/AuthDialog.vue'

export default {
  components: {
    // MainDrawer,
    SecondaryDrawer,
    AppBar,
    AuthDialog,
    Notification
  },
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style lang="scss" scoped>
.main {
  background: #f6f7ff;
}
.ispn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  color: #b8b8b8;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #536dfe;
  }
}
</style>
