import { readStorage, writeStorage } from './index'

const EDIT_TIME_COLLECTION_NAME = 'EDIT_TIME_COLLECTION'

export function getEditTimeFromLS () {
  return readStorage(EDIT_TIME_COLLECTION_NAME) || {}
}

export function saveEditTimeToLS (editTimeCollection = {}) {
  writeStorage(EDIT_TIME_COLLECTION_NAME, editTimeCollection)
}
