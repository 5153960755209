<template>
  <v-snackbar :value="notification" @input="updateNotification" :color="notificationColor" :timeout="notificationTimeout" top centered>
    {{ notificationText }}
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app', ['notification', 'notificationText', 'notificationColor', 'notificationTimeout'])
  },
  methods: {
    ...mapMutations('app', ['updateNotification'])
  }
}
</script>

<style lang="scss" scoped>

</style>
