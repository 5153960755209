import Axios from 'axios'
import NProgress from 'nprogress'

// const IS_DEV = process.env.NODE_ENV === 'development'

// const BASE_URL = IS_DEV ? 'http://localhost:9090' : 'https://game-server.doiduoyi.com'
const BASE_URL = 'https://game-server.doiduoyi.com'

const axios = Axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})

async function _request (options) {
  return new Promise((resolve, reject) => {
    NProgress.start()
    axios(options).then(({ data }) => {
      if (data.success === true) {
        let pager
        if (data.pageIndex !== undefined) {
          pager = {
            empty: data.empty,
            notEmpty: data.notEmpty,
            pageIndex: data.pageIndex,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
            totalPages: data.totalPages
          }
        }
        resolve({ data: data.data, pager })
      } else {
        reject(new Error(data.errMessage || '未知错误'))
      }
    }).catch(res => {
      if (!res.response) {
        // typeof res === Error
        reject(new Error((res.message.includes('timeout') && '请求超时') || (res.message.includes('cancel') && 'cancel') || '网络连接失败'))
      } else {
        // if (res.response.errCode === 403) {}
        reject(new Error(typeof res.response.data === 'object' ? res.response.data.errMessage : res.message))
      }
    }).finally(() => {
      NProgress.done()
    })
  })
}

export function Get (url) {
  return (params, headers = {}, config = {}) => _request({
    url,
    method: 'get',
    params: params || null,
    headers: {
      Accept: 'application/json',
      ...headers
    },
    ...config
  })
}

export function Post (url) {
  return (body, headers = {}) => _request({
    url,
    method: 'post',
    headers: {
      Accept: 'application/json',
      ...headers
    },
    data: body
  })
}

export function Delete (url) {
  return (body, headers = {}) => _request({
    url,
    method: 'delete',
    headers: {
      Accept: 'application/json',
      ...headers
    },
    data: body
  })
}

export function Put (url) {
  return (body, headers = {}) => _request({
    url,
    method: 'put',
    headers: {
      Accept: 'application/json',
      ...headers
    },
    data: body
  })
}
