import { reduce } from 'lodash-es'
import produce from 'immer'
import db from 'utils/db'

const state = {
  // {}
  artifacts: [],
  units: []
}
const getters = {
  ...reduce(db, (result, val, key) => {
    result[key] = () => val
    return result
  }, {})
}

const mutations = {
  // 反序列化的 setter
  setArtifacts (state, artifacts) {
    state.artifacts = artifacts
  },
  setUnits (state, units) {
    state.units = units
  },
  // 神器
  addArtifact (state, { artifact }) {
    state.artifacts = state.artifacts.concat(artifact)
  },
  editArtifact (state, { id, artifact }) {
    state.artifacts = produce(state.artifacts, draft => {
      const index = draft.findIndex(item => item.id === id)
      draft[index] = artifact
    })
  },
  deleteArtifact (state, { id }) {
    state.artifacts = produce(state.artifacts, draft => {
      const index = draft.findIndex(item => item.id === id)
      const pre = draft.slice(0, index)
      const post = draft.slice(index + 1)
      return pre.concat(post)
    })
  },
  // 单位
  addUnit (state, { unit }) {
    state.units = state.units.concat(unit)
  },
  editUnit (state, { id, unit }) {
    state.units = produce(state.units, draft => {
      const index = draft.findIndex(item => item.id === id)
      draft[index] = unit
    })
  },
  deleteUnit (state, { id }) {
    state.units = produce(state.units, draft => {
      const index = draft.findIndex(item => item.id === id)
      const pre = draft.slice(0, index)
      const post = draft.slice(index + 1)
      return pre.concat(post)
    })
  }
}

const actions = {

}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
