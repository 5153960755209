<template>
  <v-navigation-drawer app right clipped v-if="rightDrawer">
    <template>
      <component :is="rightDrawer"></component>
    </template>
  </v-navigation-drawer>
</template>

<script>
import MixinMenu from 'mixins/MixinMenu'
import AboutMe from 'components/AboutMe.vue'

export default {
  mixins: [MixinMenu],
  components: {
    AboutMe
  }
}
</script>

<style lang="scss" scoped>

</style>
