import Vue from 'vue'
import VueRouter from 'vue-router'
// import NProgress from 'nprogress'
import { setTitle } from '@/utils/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "articles" */ '../views/ArticleWaterfall.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/article/:id(\\d+)',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article-detail" */ '../views/ArticleDetail.vue')
  },
  {
    path: '/herohour',
    name: 'HeroHour',
    component: () => import(/* webpackChunkName: "herohour" */ '../views/hh/HeroHourWaterfall.vue'),
    meta: {
      title: '英雄之时'
    }
  },
  {
    path: '/webgame',
    name: 'WebGame',
    component: () => import(/* webpackChunkName: "webgame" */ '../views/webgame/WebWaterfall.vue'),
    meta: {
      title: '网页游戏'
    }
  },
  {
    path: '/gamedev',
    name: 'GameDev',
    component: () => import(/* webpackChunkName: "gamedev" */ '../views/gamedev/GameDevWaterfall.vue'),
    meta: {
      title: '游戏开发'
    }
  },
  {
    path: '/mod',
    name: 'Mod',
    redirect: '/mod/artifact',
    component: () => import(/* webpackChunkName: "mod" */ '../views/mod/Index.vue'),
    meta: {
      title: '模组编辑器'
    },
    children: [
      {
        path: 'artifact',
        name: 'Artifact',
        component: () => import(/* webpackChunkName: "artifact" */ '../views/mod/Artifact.vue')
      },
      {
        path: 'unit',
        name: 'Unit',
        component: () => import(/* webpackChunkName: "unit" */ '../views/mod/Unit.vue')
      }
    ]
  },
  {
    path: '/tools',
    name: 'Tool',
    redirect: '/tools/image',
    component: () => import(/* webpackChunkName: "tool" */ '../views/tools/Index.vue'),
    meta: {
      title: '工具集'
    },
    children: [
      {
        path: 'image',
        name: 'ImageConvert',
        component: () => import(/* webpackChunkName: "image-convert" */ '../views/tools/ImageConvert.vue'),
        meta: {
          title: '转换图片格式'
        }
      }
    ]
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/chat/Index.vue'),
    meta: {
      title: 'GPT'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // NProgress.start()
  const title = to.meta?.title
  if (title) {
    setTitle(title)
  }
  next()
})

router.afterEach(() => {
  // NProgress.done()
})

export default router
