const state = {
  typewriterMode: true
}
const getters = {

}

const mutations = {
  updateTypewriterMode (state, flag) {
    state.typewriterMode = flag
  }
}

const actions = {

}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
