import FileSaver from 'file-saver'

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const base64ToFile = (base64, name, type) => fetch(base64)
  .then(res => res.arrayBuffer())
  .then(buf => new File([buf], name, { type }))

export function download (content, { type, name }) {
  FileSaver.saveAs(content, name, { type })
}

export function getUrlParamMap (url) {
  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)
  return params
}

export function setTitle (title) {
  document.title = `${title} - 鼓励 Jun 的游戏库`
}

export function readStorage (key, storage = 'localStorage') {
  return JSON.parse(window[storage].getItem(key))
}

export function writeStorage (key, value, storage = 'localStorage') {
  window[storage].setItem(key, JSON.stringify(value))
}
