import AboutMe from 'components/AboutMe.vue'

// 子级排在前面
export default {
  Articles: {
    topMenu: {
      name: '首页'
    },
    rightDrawer: AboutMe
  },
  Chat: {
    topMenu: {
      name: 'ChatGPT',
      redirect: 'https://www.doiduoyi.com/?app=AI'
    },
    rightDrawer: AboutMe
  },
  GameDev: {
    topMenu: {
      name: '游戏开发'
    },
    rightDrawer: AboutMe
  },
  WebGame: {
    topMenu: {
      name: '网页游戏'
    },
    rightDrawer: AboutMe
  },
  HeroHour: {
    topMenu: {
      name: '英雄之时'
    },
    rightDrawer: AboutMe
  },
  Mod: {
    topMenu: false,
    rightDrawer: false
  },
  _default: {
    topMenu: undefined,
    rightDrawer: AboutMe
  }
}
