import Vue from 'vue'
import Vuex from 'vuex'
import app, { subscription as appSubscription } from './modules/app'
import auth from './modules/auth'
import mod from './modules/mod'
import chat from './modules/chat'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    mod,
    chat
  },
  strict: debug
})

store.subscribe((mutation, state) => {
  appSubscription(store, mutation, state)
})

export default store
